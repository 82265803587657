// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-about-page-template-js": () => import("./../../../src/components/AboutPageTemplate.js" /* webpackChunkName: "component---src-components-about-page-template-js" */),
  "component---src-components-generic-markdown-template-js": () => import("./../../../src/components/GenericMarkdownTemplate.js" /* webpackChunkName: "component---src-components-generic-markdown-template-js" */),
  "component---src-components-psalm-template-js": () => import("./../../../src/components/PsalmTemplate.js" /* webpackChunkName: "component---src-components-psalm-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-music-and-lyrics-js": () => import("./../../../src/pages/music-and-lyrics.js" /* webpackChunkName: "component---src-pages-music-and-lyrics-js" */)
}

